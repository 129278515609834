











import {
  Component, Vue, PropSync,
} from 'vue-property-decorator';

@Component
export default class EnabledOnly extends Vue {
  @PropSync('enabledOnly', { default: '' }) syncedEnabledOnly!: string;
}
