import Vue from "vue";

Vue.filter(
  'kebab-to-pascal',
  (value: string) => {
    if (!value || typeof value !== 'string') return value;

    return value
      .split('-')
      .map(x => x[0].toUpperCase() + x.substring(1))
      .join(' ');
  }
);

Vue.filter(
  'space-and-lowercase-capital-letters',
  (value: string) => {
    if (!value || typeof value !== 'string') return value;

    //compare string with its lower case version, when a difference is found prefix space and use lowercase character
    const s1 = value;
    const s2 = s1.toLowerCase();
    let result=s1[0];
    for(let  i=1; i<s1.length; i++)
    {
     if(s1[i]!==s2[i]) result = result +' ' +s2[i];
     else result = result + s2[i];
    }
    return result;
  }
);
