var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticStyle:{"flex-basis":"50%"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.syncedFilter),callback:function ($$v) {_vm.syncedFilter=$$v},expression:"syncedFilter"}})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('enabled-only',{attrs:{"enabled-only":_vm.syncedEnabledOnly},on:{"update:enabledOnly":function($event){_vm.syncedEnabledOnly=$event},"update:enabled-only":function($event){_vm.syncedEnabledOnly=$event}}})],1)]),_c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"dense":"","item-key":"id","page":_vm.syncedPage,"items-per-page":_vm.syncedItemsPerPage,"server-items-length":_vm.serverItemsLength,"hide-default-footer":""},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}}),{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"subheading font-weight-light text-success text--darken-3",domProps:{"textContent":_vm._s(header.text)}})]}},(_vm.options.link)?{key:"item.name",fn:function(props){return [_c('router-link',{staticClass:"library-link",attrs:{"to":{ name: 'library', params: { id: props.item.id } }}},[_vm._v(" "+_vm._s(props.item.name)+" ")])]}}:null,{key:"item.metadata",fn:function(ref){
var value = ref.value;
return _vm._l((Object.keys(value)),function(key){return _c('v-chip',{key:key,staticClass:"ma-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(key)+": "+_vm._s(value[key])+" ")])})}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"hide-details":"auto","color":"darkgrey","value":item.enabled,"disabled":!_vm.hasHandler('item:enable')},on:{"input":function($event){return _vm.$emit('item:enable', { item: item, enable: $event })}}})]}},(_vm.hasHandler('item:delete'))?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('item:delete', item)}}},[_vm._v(" mdi-delete ")])]}}:null],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})}),(_vm.apiFault)?_c('template',{slot:"no-data"},[_vm._v(" Unable to fetch results from the API ")]):_vm._e()],2),_c('v-pagination',{attrs:{"length":_vm.numberOfPages,"total-visible":"20"},model:{value:(_vm.syncedPage),callback:function ($$v) {_vm.syncedPage=$$v},expression:"syncedPage"}}),_c('v-select',{attrs:{"id":"itemsPerPage","label":"Libraries per page:","items":[10, 20, 50]},model:{value:(_vm.syncedItemsPerPage),callback:function ($$v) {_vm.syncedItemsPerPage=$$v},expression:"syncedItemsPerPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }