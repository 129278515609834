export enum LibraryImportMessageType {
  CustomNote = 0,
  CreateRule = 1,
  DeleteRule = 2,
  UndeleteRule = 3,
  UpdateRule = 4,
  RuleNotModified = 5,
  CreateTemplate = 6,
  DeleteTemplate = 7,
  UndeleteTemplate = 8,
  UpdateTemplate = 9,
  TemplateNotModified = 10,
  CreateLibrary = 11,
  DeleteLibrary = 12,
  UndeleteLibrary = 13,
  UpdateLibrary = 14,
  LibraryNotModified = 15
}

export type LibraryImportMessage = {
  updateType: LibraryImportMessageType;
  updateTargetName: string;
  customMessage: string;
};
