

































































































import {
  Component, Vue, Prop, PropSync,
} from 'vue-property-decorator';

import { TableHeader } from '@/components/layout/models/table.d';

import { TableOptions } from '@/models/form';

import config from '@/config';
import EnabledOnly from './enabled-only.vue';

@Component({
  components: {
    EnabledOnly
  },
})
export default class LibrariesTable extends Vue {
  @Prop({ default: () => ({}) }) options!: TableOptions;

  @Prop({ default: 0 }) serverItemsLength!: number;

  @Prop({ default: false }) apiFault!: boolean;

  @PropSync('itemsPerPage', { default: () => config.defaultPageSize }) syncedItemsPerPage!: number;

  @PropSync('page', { default: 1 }) syncedPage!: number;

  @PropSync('filter', { default: '' }) syncedFilter!: string;

  @PropSync('enabledOnly', { default: '' }) syncedEnabledOnly!: string;

  headers: TableHeader[] = [];

  get numberOfPages() {
    if (this.syncedItemsPerPage === 0) return 1;
    return Math.max(1, Math.ceil(this.serverItemsLength / this.syncedItemsPerPage));
  }

  hasHandler(name: string): boolean {
    return name in this.$listeners;
  }

  async mounted(): Promise<void> {
    this.headers = [
      {
        sortable: false,
        sortDirection: '',
        text: 'Library Name',
        value: 'name',
      },
      {
        sortable: false,
        sortDirection: '',
        text: 'Description',
        value: 'description',
      },
      {
        sortable: false,
        sortDirection: '',
        text: 'Metadata',
        value: 'metadata',
      },
      {
        sortable: false,
        sortDirection: '',
        text: 'Enabled',
        value: 'enabled',
      },
    ];

    if (this.hasHandler('item:delete')) {
      this.headers.push(
        {
          sortable: false,
          sortDirection: '',
          text: 'Delete',
          value: 'actions',
        },
      );
    }
  }
}
